import { LAB_TEST_API_BASE_URL, userStorageName } from '@/services/ecommerce/apis/config';
import { getRefreshedToken, isRefreshing, waitForTokenRefresh } from '@labServices/utils/inMemoryJWTManager';
import { getItemFromLocalStorage, handleLogout } from '@labServices/utils/session';
import { redirect } from 'next/navigation';

import querystring from 'qs';
import parser from 'ua-parser-js';

interface ParsedAgent {
	browser?: {
		name?: string;
		version?: string;
	};
	os?: {
		name?: string;
		version?: string;
	};
}

const baseApiUrl: Record<string, any> = {
	// api: `http://192.168.0.221:3020`,

	// lab-test
	'lab-search': LAB_TEST_API_BASE_URL,
	'lab-order': LAB_TEST_API_BASE_URL,
	misc: LAB_TEST_API_BASE_URL,
	patient: LAB_TEST_API_BASE_URL,

	// doc-consultation
	doctor: LAB_TEST_API_BASE_URL,
	'doc-consultation': LAB_TEST_API_BASE_URL
};

export const checkLabTest = (resource: string, index: number = 0): string | boolean => {
	const labPrefix = resource.split('/')[index];
	if (labPrefix && baseApiUrl[labPrefix]) return baseApiUrl[labPrefix];
	return false;
};

export const getBaseApiUrl = (endpoint: string) => {
	const isLabTest = checkLabTest(endpoint);
	if (!!isLabTest) return isLabTest;
	return LAB_TEST_API_BASE_URL;
};

const agentChecker = (userAgentVar: string): ParsedAgent => {
	const { browser, os } = parser(userAgentVar);
	return { os, browser };
};

const getUrl = (endpoint: string, userAgentVar?: string): string => {
	const { os, browser } = agentChecker(userAgentVar || '');
	const extra = querystring.stringify({
		f: ['iPhone', 'iPod', 'iPad', 'Android', 'BlackBerry', 'Mobile'].includes(os?.name || '') ? 'mobile' : 'web',
		b: browser?.name || '',
		v: browser?.version || '',
		os: os?.name || '',
		osv: os?.version || ''
	});

	return `${getBaseApiUrl(endpoint)}/${endpoint}${endpoint.includes('?') ? '&' : '?'}${extra}`;
};

const handleResponse = async (endpoint: string, method: string, body: any, headers: any): Promise<any> => {
	getRefreshedToken()
		.then((gotFreshToken) => {
			let newHeaders = { ...headers };
			if (gotFreshToken) {
				newHeaders = {
					...newHeaders,
					// Authorization: `Bearer ${getToken()}`,
					Authorization: `Bearer ${getItemFromLocalStorage(userStorageName)?.authToken}`
				};
			} else {
				handleLogout();
				return;
			}
			return request({
				endpoint,
				method,
				body,
				newHeaders
			});
		})
		.catch((err) => {
			handleLogout();
		});
};

export const request = async ({
	endpoint,
	method = 'GET',
	body,
	headers = {},
	withCredentials = false,
	customOptions,
	nextHeaders,
	revalidateTime
}: any): Promise<any> => {
	if (isRefreshing !== null) {
		await waitForTokenRefresh();
	}

	const user = getItemFromLocalStorage(userStorageName);
	// const authToken = getToken();
	const authToken = user?.authToken;
	const authHeaders: Record<string, string> = authToken ? { Authorization: `Bearer ${authToken}` } : {};

	try {
		const url = getUrl(endpoint);
		const isServer = typeof window === 'undefined';

		const requestBody = body
			? typeof window !== 'undefined' && typeof FormData !== undefined && body instanceof FormData
				? body
				: querystring.stringify(body)
			: undefined;

		let contentTypeObj: Record<string, any> = {
			'Content-Type': 'application/x-www-form-urlencoded'
		};

		if (customOptions?.contentType) {
			contentTypeObj['Content-Type'] = customOptions.contentType;
		}

		if (customOptions?.isFormData) {
			contentTypeObj = {};
		}

		const fetchOptions: RequestInit = {
			method,
			revalidateTime,
			headers: {
				...(isServer && { 'X-Token': process.env.X_TOKEN || '' }),
				...(body ? contentTypeObj : {}),
				...headers,
				...authHeaders
			},
			next: { revalidate: revalidateTime || 0 },
			credentials: withCredentials ? 'include' : 'omit',
			...(nextHeaders ? nextHeaders : {})
		};

		if (body && method !== 'GET') {
			fetchOptions.body = requestBody;
		}

		const response = await fetch(url, fetchOptions);

		if (!response.ok) {
			console.log('error', url);
			if (response.status === 403) {
				redirect('/403');
			}
			if (response.status === 401) {
				return handleResponse(endpoint, method, body, headers);
			}
		}

		return await response.json();
	} catch (error) {
		console.log('error', error);
	}
};

// export const getCategories = async (): Promise<any> =>
// 	request({
// 		endpoint: "v1/categories/",
// 	});

export const getCategories = async (): Promise<any> =>
	request({
		endpoint: 'v1/categories/'
	});

// product
export const medicineDetails = (id: any, lang: string = 'en'): Promise<any> =>
	request({
		endpoint: `v2/medicine/${id}/?lang=${lang}`
	});
export const getMedicines = (obj: Record<string, any>): Promise<any> =>
	request({
		endpoint: `v1/medicines/?${querystring.stringify(obj)}`,
		method: 'GET'
	}) as Promise<any>;
export const extraMedicine = (id: string, extra: boolean = false, aq?: any): Promise<any> => {
	const endpoint = extra ? `v2/medicine/extra/${id}?${querystring.stringify(aq)}` : `v3/medicine/${id}`;

	return request({ endpoint, method: 'GET' });
};
export const getSpecialOffers = (): Promise<any> =>
	request({
		endpoint: 'v1/offers',
		method: 'GET'
	});

// auth
export const postAuthSMSSend = (mobile: any, fcmToken: any, referral: any) =>
	request({
		endpoint: 'v1/auth/sms/send',
		method: 'POST',
		body: {
			mobile,
			fcmToken,
			referral
		}
	});

export const postAuthSMSVerify = (mobile: any, otp: any, fcmToken: any, referral: any) =>
	request({
		endpoint: 'v1/auth/sms/verify',
		method: 'POST',
		withCredentials: true,
		body: {
			mobile,
			otp,
			fcm_token: fcmToken,
			referral
		}
	});

export const postLogout = () =>
	request({
		endpoint: 'v1/auth/logout/',
		method: 'POST',
		withCredentials: true
	});
// user
export const getCartDetails = (q = '', user: any) => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `cart/v1/?${qs}`,
		method: 'GET',
		user
	});
};

export const addItemToCart = (m_id: any, qty: any, q: any, user: any) => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `cart/v1/${m_id}/?${qs}`,
		method: 'POST',
		body: {
			qty
		}
	});
};
export const deleteCartItem = (m_id: any, additionalQuery: any, user: any) =>
	request({
		endpoint: `cart/v1/${m_id}/?${querystring.stringify(additionalQuery)}`,
		method: 'DELETE',
		user
	});
// export const requestStock = (m_id: any, user: any) =>
// 	request({
// 		endpoint: "v1/requestStock/",
// 		method: "POST",
// 		body: {
// 			m_id,
// 		},
// 	});

export const userProfileUpdate = (
	u_name: string,
	u_mobile: string,
	u_email: string,
	u_sex: string,
	u_dob: string,
	u_profile_pic: any
) => {
	const data: any = {
		u_name,
		u_mobile,
		u_email,
		u_sex,
		u_dob,
		u_profile_pic
	};
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		const value = data[key];
		if (!value) {
			return;
		}
		formData.append(key, value);
	});

	return request({
		endpoint: 'v1/profile',
		method: 'POST',
		body: formData,
		customOptions: {
			isFormData: true
		}
	});
};

export function allLocations() {
	return request({
		endpoint: `v1/allLocations/`,
		method: 'GET'
	});
}

export function getUserDefaultAddress(obj?: any) {
	return request({
		endpoint: 'userLocation/v1/default/',
		method: 'GET'
	});
}

export function getUserAddresses(obj?: any) {
	return request({
		endpoint: `userLocation/v1/?${querystring.stringify(obj)}`,
		method: 'GET'
	});
}

export function setNewAddress(addressObj: any) {
	return request({
		endpoint: `userLocation/v1/`,
		method: 'POST',
		body: addressObj
	});
}

export function updateAddress(locationId: string, addressObj: any) {
	return request({
		endpoint: `userLocation/v1/${locationId}`,
		method: 'POST',
		body: addressObj
	});
}

export function getSingleAddress(locationId: string) {
	return request({
		endpoint: `userLocation/v1/${locationId}`,
		method: 'GET'
	});
}

export function deleteAddress(locationId: string) {
	return request({
		endpoint: `userLocation/v1/${locationId}`,
		method: 'DELETE'
	});
}

export function setCartDefaultAddress(locationId: string) {
	return request({
		endpoint: `cart/v1/address`,
		method: 'POST',
		body: {
			ul_id: locationId
		}
	});
}
