import { I_ConsultationFeesState } from '@/doc-consultation/presentation/molecules/DoctorPortal/ConsultationFeesCard';
import { I_DoctorExperienceState } from '@/doc-consultation/presentation/molecules/DoctorPortal/ExperienceCard';
import { I_DoctorPaymentMethodState } from '@/doc-consultation/presentation/molecules/DoctorPortal/PaymentMethodCard';
import { I_DoctorQualificationState } from '@/doc-consultation/presentation/molecules/DoctorPortal/QualificationCard';
import { I_SpecialityIdsState } from '@/doc-consultation/presentation/molecules/DoctorPortal/SpecialistCard';
import { I_DoctorProfileState } from '@/doc-consultation/presentation/organisms/DoctorPortal/DrProfile/BasicInfo';
import { request } from '@labServices/apis/arogga-api';
import querystring from 'qs';
import { IC_DoctorQualification } from '../types/doctor-portal/type.addDoctorQualification';
import { IU_DoctorProfile } from '../types/doctor-portal/type.updateDoctorProfile';
import { IU_DoctorConsultationFees } from '../types/doctor-portal/type.updateDrConsultationFees';
import { IC_DoctorPaymentMethod, IU_DoctorPaymentMethod } from '../types/doctor-portal/type.updateDrPaymentMethod';
import { IU_DoctorSpeciality } from '../types/doctor-portal/type.updateDrSpeciality';
import { IC_DoctorWishList, IC_DoctorWishListBody } from '../types/doctor/type.addDoctorToWishList';
import { IG_DoctorInfo } from '../types/doctor/type.getAvailableDoctors';
import { IG_DoctorsSearchCriteria } from '../types/doctor/type.getDoctersSearchCriteria';
import { IG_DoctorExperience } from '../types/doctor/type.getDoctorExperiences';
import { IG_DoctorProfile } from '../types/doctor/type.getDoctorProfile';
import { IG_DoctorSchedule } from '../types/doctor/type.getDoctorSchedule';
import { IG_DoctorSearch } from '../types/doctor/type.getDoctorSearch';
import { IG_DoctorSpecialityInfo } from '../types/doctor/type.getDoctorSpecialities';
import { IG_DoctorWishList } from '../types/doctor/type.getDoctorWishList';
import { IG_DoctorRecentViewInfo } from '../types/doctor/type.getRecentViewDoctors';
import { IG_DoctorTreatmentCategoryInfo } from '../types/doctor/type.getTreatmentCategories';
import { I_ResponseDataArray, I_ResponseDataObj } from './types/type.doc-consultation-apis';

export const createDoctorProfile = (body: any) => {
	return request({
		endpoint: `doctor/api/v1/public/doctors`,
		method: 'POST',
		body: body,
		customOptions: {
			isFormData: true
		}
	});
};

export const getAvailableDoctors = (): Promise<I_ResponseDataArray<IG_DoctorInfo>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/available`,
		method: 'GET',
		revalidateTime: 0
	});
};

export const getDoctorSpecialties = (): Promise<I_ResponseDataArray<IG_DoctorSpecialityInfo>> => {
	return request({ endpoint: `doctor/api/v1/public/speciality`, method: 'GET' });
};

export const updateDoctorSpecialties = (
	body: Partial<I_SpecialityIdsState>
): Promise<I_ResponseDataArray<IU_DoctorSpeciality>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/modify-doctor-speciality`,
		method: 'PUT',
		body: body
	});
};

export const getTreatmentCategories = (): Promise<I_ResponseDataArray<IG_DoctorTreatmentCategoryInfo>> => {
	return request({ endpoint: `doctor/api/v1/public/treatment-category`, method: 'GET' });
};

export const getRecentViewDoctors = (): Promise<I_ResponseDataArray<IG_DoctorRecentViewInfo>> => {
	return request({ endpoint: `doctor/api/v1/public/doctors/recent-view`, method: 'GET' });
};

export const getDoctorProfile = (id: string | number): Promise<I_ResponseDataObj<IG_DoctorProfile>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/${id}`,
		method: 'GET'
	});
};

export const updateDoctorProfile = (
	body: Partial<I_DoctorProfileState>
): Promise<I_ResponseDataObj<IU_DoctorProfile>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors`,
		method: 'PUT',
		body: body,
		customOptions: { isFormData: true }
	});
};

export const updateDoctorConsultationFees = (
	body: Partial<I_ConsultationFeesState>
): Promise<I_ResponseDataObj<IU_DoctorConsultationFees>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/update-fees`,
		method: 'PUT',
		body: body
	});
};
export const addDoctorPaymentMethod = (
	body: Partial<I_DoctorPaymentMethodState>
): Promise<I_ResponseDataObj<IC_DoctorPaymentMethod>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/payment-method`,
		method: 'PUT',
		body: body
	});
};
export const updateDoctorPaymentMethod = (
	id: string,
	body: Partial<I_DoctorPaymentMethodState>
): Promise<I_ResponseDataObj<IU_DoctorPaymentMethod>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/payment-method/${id}`,
		method: 'PUT',
		body: body
	});
};

export const getDoctorExperience = (id: string | number): Promise<I_ResponseDataArray<IG_DoctorExperience>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/experience/${id}`,
		method: 'GET'
	});
};
export const addDoctorExperience = (
	body: Partial<I_DoctorExperienceState>
): Promise<I_ResponseDataArray<IG_DoctorExperience>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/experience`,
		method: 'POST',
		body: body
	});
};

export const updateDoctorExperience = (
	id: string,
	body: Partial<I_DoctorExperienceState>
): Promise<I_ResponseDataArray<IG_DoctorExperience>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/experience/${id}`,
		method: 'PUT',
		body: body
	});
};

export const deleteDoctorExperience = (id: string): Promise<I_ResponseDataObj<boolean>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/experience/${id}`,
		method: 'DELETE'
	});
};

export const addDoctorQualification = (
	body: I_DoctorQualificationState
): Promise<I_ResponseDataObj<IC_DoctorQualification>> => {
	return request({
		endpoint: `doctor/api/v1/public/qualifications`,
		method: 'POST',
		body: body,
		customOptions: {
			isFormData: true
		}
	});
};

export const updateDoctorQualification = (id: string, body: Partial<I_DoctorQualificationState>) => {
	return request({
		endpoint: `doctor/api/v1/public/qualifications/${id}`,
		method: 'PUT',
		body: body,
		customOptions: {
			isFormData: true
		}
	});
};

export const deleteDoctorQualification = (id: string) => {
	return request({
		endpoint: `doctor/api/v1/public/qualifications/${id}`,
		method: 'DELETE'
	});
};

export const getDoctorSchedule = (id: string | number): Promise<I_ResponseDataArray<IG_DoctorSchedule>> => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/schedule/${id}`,
		method: 'GET'
	});
};

export const getFilteredDoctors = (q: object): Promise<I_ResponseDataArray<IG_DoctorSearch>> => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-search/api/v1/doctor-search?${qs}`,
		method: 'GET'
	});
};

export const getDoctorsSearchCriteria = (): Promise<I_ResponseDataObj<IG_DoctorsSearchCriteria>> => {
	return request({
		endpoint: `lab-search/api/v1/doctor-search/criteria`,
		method: 'GET'
	});
};

interface IU_OnlineVisibility {
	isOnline: boolean;
	isDoctor: boolean;
}
export const updateOnlineVisibility = (body: IU_OnlineVisibility) => {
	const { isOnline, isDoctor } = body;
	const endpoint = isDoctor
		? `doctor/api/v1/public/doctors/online-visibility`
		: `doc-consultation/api/v1/users/online-visibility`;

	return request({
		endpoint: endpoint,
		method: 'PUT',
		body: {
			isOnline
		}
	});
};

export const addDoctorToWishList = (body: IC_DoctorWishListBody): Promise<I_ResponseDataObj<IC_DoctorWishList>> => {
	const { doctorUqid } = body;
	return request({
		endpoint: `doctor/api/v1/public/doctors/wishlist`,
		method: 'POST',
		body: {
			doctorUqid
		}
	});
};

export const getDoctorWishList = (q: object): Promise<I_ResponseDataArray<IG_DoctorWishList>> => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `doctor/api/v1/public/doctors/wishlist?${qs}`,
		method: 'GET'
	});
};

export const deleteDoctorFromWishList = (wishlistId: string) => {
	return request({
		endpoint: `doctor/api/v1/public/doctors/wishlist/${wishlistId}`,
		method: 'DELETE'
	});
};
